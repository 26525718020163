@keyframes scaleUp {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

.photo-container p {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  font-family: Russo One;
  z-index: 10;
}

.photo-container .photo-object.scaleUp {
  animation: scaleUp 1s forwards;
}

.photo-container .photo-object.scaleDown {
  animation: scaleDown 1s forwards;
}

.photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

@media (max-width: 768px) {
  /* .photo-container {
    height: 100vh;
  } */

  .photo-container img {
    margin-top: 35px !important;
  }
}

.photo-container .row {
  width: 100%;
  margin: 0;
}

.photo-container img {
  max-width: 100%;
  max-height: 45vh;
  width: auto;
  height: auto;
  margin: 10px;
}
