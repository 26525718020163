button {
  display: block;
  font-size: 24px;
  font-family: sans-serif;
  text-decoration: none;
  color: #333;
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  padding: 5px;
  letter-spacing: 2px;
  transition: all 0.25s;
}

.steps-button {
  min-width: 150px;
}

@media (min-width: 768px) {
  .steps-button {
    min-width: 300px;
  }

  button:hover {
    letter-spacing: 15px;
  }
}
