.photo {
  border-radius: 24px;
  box-shadow: 12px 12px 24px rgba(0, 0, 0, 0.2);
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.text-container {
  height: 100vh;
}

@media (max-width: 1200px) {
  .text-container {
    height: 100%;
  }
}